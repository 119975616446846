// mini cart icon to the cart count button - Mob view

.minicart-wrapper{
    .action.showcart {
        .counter.qty{
            top: -11px;
            right: -6px;
            border-radius: 50px;
            @include max-screen($screen__m) {
                height: 19px;
                line-height: 19px;
                min-width: 16px;
                padding: 0 2px;
            }
        }
    }
    @include max-screen($screen__m) {
        .text {
            display: inline-block;
            position: inherit;
        }
    }
}
@include max-screen($screen__m ) {
    .opc-estimated-wrapper{
        .minicart-wrapper{
            .action.showcart{
                background-image: url(/images/cart.svg);
                background-position: top center;
                background-repeat: no-repeat;
                background-size: auto 24px;
                font-size: 10px;
                font-weight: 600;
                position: relative;
                top: 7px;
                background-color: transparent;
                border: none;
                width: 32px;
                padding-top: 30px;
                right: 6px;
            }
        } 
    }
}