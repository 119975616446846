.awards {
	margin: 60px auto;
	max-width: 1400px;
	display: flex;
	@include max-screen($screen__l) {
		flex-direction: column;
		margin: 30px 15px;
	}

	&__title {
		width: 50%;
		text-align: right;
		@include max-screen($screen__l) {
			width: 100%;
			text-align: left;
		}
		h2 {
			font-size: 34px;
			line-height: 150px;
			margin: 0;
			@include max-screen($screen__l) {
				line-height: 1.1;
			}

			@include max-screen($screen__s) {
				font-size: 24px;
			}
		}
	}

	&__logos {
		width: 50%;
		text-align: left;
		display: flex;
		margin: 0;
		@include max-screen($screen__l) {
			width: 100%;
			padding: 0;
			margin: 15px 0;

		}
		li {
			display: inline;
			margin: 15px;
			height: 150px;
			@include max-screen($screen__l) {
				margin: 0;
				height: 120px;
			}
			img {
				max-height: 150px;
				vertical-align: middle;
				@include max-screen($screen__l) {
					max-height: 120px;
				}
			}
		}
	}
}
