@keyframes ellipsis {
  0% {
    width: 0;
  }

  75% {
    width: 1.5ch;
  }

  100% {
    width: 2ch;
  }
}

/* General */

.wb-mp__field-note {
  color: #303030;
  font-size: 12px;
  margin-top: 5px;
}

table.hover-light tr:hover td {
  background-color: #f0f0f0;
  cursor: pointer;
}

.wb-mp__actions {
  text-align: right;
  margin-bottom: 5px;

  button {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 768px) {
  .wb-mp__actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;

    button {
      width: 46%;
      margin: 5px 2%;
    }
  }

  .wb-mp-list-toolbar .toolbar-amount {
    position: static;
  }

  .wb-mp__title {
    margin-bottom: 0;

    .page-title {
      white-space: normal;
      margin-bottom: 25px;
      font-size: 2.0rem;
    }
  }
}

@media screen and (min-width: 768px) {
  .wb-mp__title .page-title {
    font-size: 2.5rem;
    margin: 0;
  }
}

.wb-mp__title {
  display: inline-block;
  width: 100%;
  margin-bottom: 40px;

  .page-title {
    display: block;
    float: left;
    text-overflow: ellipsis;
  }
}

.wb-mp__confirm .modal-content {
  white-space: pre-wrap;
}

/* Order view page */
@media screen and (min-width: 768px) {
  .order-actions-toolbar .action.wb-mp-request-return {
    display: block;
    float: right;
  }

  .wb-mp__field {
    > {
      .label {
        width: calc((100%) * 0.3) !important;
        line-height: 3.2rem;
        float: left;
        text-align: right;
      }

      .control {
        width: calc((100%) * 0.5 - 15px) !important;
        margin-left: 15px;

        span {
          line-height: 3.2rem;
        }
      }
    }

    padding-top: 2px;
  }
}

@media screen and (max-width: 768px) {
  .wb-mp__field>.label {
    display: block !important;
  }
}

.wb-mp__field {
  > {
    .label {
      padding: 0;
    }

    .control {
      float: left;
      width: 100%;
    }
  }

  margin: 0 0 8px !important;
  display: block;
  overflow: auto;

  &:after {
    clear: both;
    content: '';
  }
}

/* Manage RMA request page */

.wb-mp__block-general {
  background: #f5f5f5;
  padding: 20px;
}

.wb-mp__view-items .wb-mp__return-item-container .item-details .product-details {
  margin-left: 0;
}

@media (min-width: 768px) {
  .wb-mp__view-items .wb-mp__return-item-container {
    .item-details {
      width: 20%;
    }

    .item-return-details {
      width: 80%;
      margin-left: 20%;
    }
  }
}

.wb-mp__view .shipping-address-previewer .edit-address-link {
  margin: 0;
  padding: 0;
  color: #1979c3;
  text-decoration: none;
  background: none;
  border: 0;
  box-shadow: none;
  font-size: 13px;
  font-weight: normal;
  outline: none;
}

/* Thread */

.wb-mp__block-thread .messages {
  list-style: none;
  padding: 0;

  li {
    display: block;
    margin-bottom: 1rem;
    padding: 2rem;
    width: 70%;
    box-sizing: border-box;

    &.admin {
      background: #f5f5f5;

      &.auto {
        background: #f5f5f5;
      }
    }

    &.customer {
      background: #1979c3;
      margin-left: 30%;
      color: #fff;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  .wb-mp-view__attachment {
    margin-top: 1rem;
  }

  .wb-mp-view__message-text {
    margin-top: 1rem;
    white-space: pre-wrap;
  }
}

/* New RMA page (step 1) */

.wb-mp__select-order-table {
  tbody .order-item-row:nth-child(even) {
    background: #f1f1f1;
  }

  .items-info ul {
    margin: 0;
    padding: 0;
    list-style: none none;

    li {
      margin: 0;
      padding: 0;
    }
  }

  .order-row {
    cursor: pointer;

    &.selected {
      background: #e8e8e8;
    }
  }

  .bundle-option .option-label {
    font-weight: 600;
  }

  .order-message,
  .item-message {
    color: #e02b27;
  }
}

.wb-mp__return-item-container .item-message {
  color: #e02b27;

  &.tick-checkbox {
    text-align: center;
    color: #5f5f5f;
    font-size: 1.6rem;
    margin-bottom: 20px;
  }
}

/* New RMA page (step 2) */

.wb-mp__policy {
  text-align: right;
  margin-bottom: 5px;
}

.wb-mp__return-item-container {

  .item-return-details:after,
  &:after {
    clear: both;
  }

  .item-return-details {

    &:after,
    &:before {
      content: '';
      display: table;
    }
  }

  &:after,
  &:before {
    content: '';
    display: table;
  }

  .item-return-details .fieldset {
    margin: 0;
  }

  .item-details {
    position: relative;
    word-wrap: break-word;

    .product-details {
      margin-left: 25px;
    }

    .item-select {
      position: absolute;
      top: 50%;
    }
  }

  margin-bottom: 20px;

  .item-return-details {
    background: #f5f5f5;

    .items-to-return {
      .control {
        width: 70px !important;
      }

      .qty-total {
        margin-left: 5px;
        line-height: 3.2rem;
        float: left;
      }
    }
  }
}

@media (min-width: 768px) {
  .wb-mp__return-item-container {
    .item-details {
      clear: left;
      float: left;
      width: 25%;
    }

    .item-return-details {
      width: 75%;
      margin-left: 25%;

      .content {
        margin: 20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .wb-mp__return-item-container {
    .item-return-details .content {
      margin: 10px;
    }

    .item-details {
      margin-bottom: 10px;
    }
  }
}

/* Guest  Missing Part */

.wb-mp-new-guest {

  .block-guest-order,
  .block-customer-login {
    width: 48%;
  }

  .block-guest-order {
    float: right;
  }

  .block-customer-login {
    float: left;
  }

  .block-title {
    font-size: 1.8rem;
    padding-bottom: 12px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e8e8e8;

    strong {
      font-weight: 500;
    }
  }

  .fieldset .field {
    &.note {
      min-height: 4em;
    }

    .control {
      width: 55%;
    }
  }
}

.wb-mp-list-toolbar .limiter {
  float: right;
}
//missingpart request login
.missingpart-request-index,.missingpart-request-create {
  #maincontent {
    background-color: #eeeeee;
    margin-bottom: 50px;
  }

  .page-title-wrapper {
    padding: 40px 100px;
    @include max-screen($screen__m) {
      padding: 30px 15px;
      margin-left: 0;
    }

    h1.page-title {
      margin-bottom: 0;
      @include max-screen($screen__m) {
        font-size: 24px;
      }
      @include max-screen($screen__xs) {
        font-size: 18px;
      }
    }

  }
  .column.main {
    background-color: white;   
    form{
      margin-top: 50px;
      legend{
        font-weight: bold;
        margin-bottom: 30px;
        @include max-screen($screen__m) {
          font-size: 18px;
        }
        @include max-screen($screen__xs) {
          font-size: 16px;
        }
      }
    }
  }
  .wb-mp__actions,.actions-toolbar{
    button{
      border: none;
      border-radius: 0;
      background-color: #f69704;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 20px !important;  
      margin: 0;           
      color: white;
      text-transform: uppercase;
      width: auto;
    }
  }
}

//missingpart request create page styling
.missingpart-request-create {
  .column.main {
    .block-rma-form {
      form {
        display: flex;
        flex-wrap: wrap;       
        &>* {
          flex-basis: 100%;
        }
        *{
          margin-bottom: 0;
          padding-bottom: 0;
        }    

        .fieldset.block2 {
          flex-basis: 30%;
          @include max-screen($screen__m) {
            flex-basis: 40%;
          }
          @include max-screen($screen__xs) {
            flex-basis: 60%;
          }
          .content-row{
            margin-bottom: 30px;
          }
        }
        .fieldset.block3 {
          flex-basis: 70%;
          @include max-screen($screen__m) {
            flex-basis: 60%;
          }
          @include max-screen($screen__xs) {
            flex-basis: 40%;
            .wb-mp__return-massaction{
              a{
                display: block;
              }
            }
          }
        }
        .fieldset.block5 {
          margin-top: 30px;
        }
        
        .wb-mp__return-items{    
              
          .header-row{
            display: flex;
            justify-content: space-between;
            margin-bottom: 50px;          
            align-items: center;
            .header-labels{
              width: 75%;
              text-align: center;
              font-weight: bold;
              font-size: 18px;
              @include max-screen($screen__m) {
                font-size: 16px;
              }
              @include max-screen($screen__xs) {
                font-size: 14px;
              }
              &.product-name{
                text-align: left;
                margin-right: 20px;
              }
            }
          }
          .wb-mp__return-bundle-item-container{  
              display: flex;
              justify-content: space-between;       
              flex-direction: column;
              .bundle-product-title{
                margin-bottom: 20px;
              }      
          }   
          .wb-mp__return-item-container{
            margin-bottom: 20px !important;
            display: flex;
            .item-details{
              clear: none;
              float: none;
              width:auto;
              flex-basis: 25%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              @include max-screen($screen__xs) {
                flex-basis: 40%;
              }
            }
          }     
          .product-details{
            margin-right: 25px;
            .product-image-wrapper{
              padding-bottom: 0 !important;             
            }
          }     
          .item-return-details-outer{
            display: flex;
            justify-content: space-between;
            background: #f5f5f5;
            padding: 50px 20px;
            flex-grow: 1;
            @include max-screen($screen__xs) {
              padding: 10px;
            }
            .item-return-details{
              background: transparent;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0;
              flex-basis: 33.33%;          
              .content{
                min-height: 45px;
                display: flex;
                align-items: center;
                margin: 0 !important;
              }
              label{
                padding: 0;
                width: auto !important;
              }
              .field.wb-mp__field.items-to-return{
                padding: 0 !important;
              }
            }
            .item-return-details,.item-message.tick-checkbox span{
              @include max-screen($screen__xs) {                 
                font-size: 12px !important;                 
              }
            }
          }
          
        }
        .additional-info{
          #sidebar{
            margin-bottom: 30px;
          }
        }
        .wb-mp__policy{
          display: flex;
          input{
            margin-right: 15px;
          }
          span{
            margin-right: 5px; 
          }
        }
        .wb-mp__actions{
          text-align: left;
          display: flex;          
          margin-top: 50px;  
          justify-content: flex-start!important; 
          @include max-screen($screen__m) {
            justify-content: space-between!important;            
          }      
          .wb-mp-create-button-wrapper{
            flex-basis: 80%;
            @include max-screen($screen__m) {
              flex-basis: auto;              
            }
          }      
          div>span{
            color: red;
          }
        }
      }
    }
  }
}
//success page
.missingpart-request-save{ 
  #maincontent{
    margin-top: 100px;
    margin-bottom: 100px;
    .page-title-wrapper{
      padding: 0 100px;
      @include max-screen($screen__m) {
        padding: 0;             
      }
    }
  }
}


 