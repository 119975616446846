.free-design-service {
    max-width: 1200px;
    margin: 60px auto 120px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-top: 60px;
    &__step {
        max-width: 300px;
        padding-bottom: 60px;
        h2 {
            padding-top: 100px;
            background-repeat: no-repeat;
            background-position: top left;
            @include max-screen($screen__m) {
                background-position: center top;
            }
        }
        &:nth-child(1) h2 {
            background-image: url(../images/contact_us.svg);
        }
        &:nth-child(2) h2 {
            background-image: url(../images/receive_designs.svg);
        }
        &:nth-child(3) h2 {
            background-image: url(../images/buy_with_confidence.svg);
        }
        a.hero__content__readmore {
            position: absolute;
            bottom: 0;
            margin: 0;
            @include max-screen($screen__m) {
                bottom: unset;
                margin: 15px 0;
            }
        }
        @include max-screen($screen__m) {
            max-width: none;
        }
    }
     @include max-screen($screen__m) {
        flex-direction: column;  
        margin: 15px;      
    }

    .toolbar-products {
        display: none;
    }

   
}

.hero__container--free-design {
    max-width: 1400px;
    .hero__item { 
    .hero__content {
        padding-top: 0;
    }
}
}
// Breadcrumbs 
.category-free-bespoke-garden-design-service{
    .top-container{
        .breadcrumbs{
            @include max-screen($screen__l) {
                margin: 10px  auto 0;
                padding: 0 15px;
                width: 50%;
            }
            @include max-screen($screen__m) {
                width: 100%;
            }
        }
    }
}

.free-design-service__design-pack {
    max-width: 1200px;
    margin: 60px auto 120px auto;
    padding-top: 0;
    
    h2 {
        margin-bottom: 6rem;
    }

    @include max-screen($screen__l) {
        margin: 25px auto;
        padding: 0 15px;
        h2 {
            margin-bottom: 1rem;
        }
    }
}