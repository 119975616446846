.youtube {
	background-color: #000;
	margin-bottom: 30px;
	position: relative;
	padding-top: 56.25%;
	overflow: hidden;
	width: 100%;
	cursor: pointer;
	img {
		width: 100%;
		top: -16.82%;
		left: 0;
		opacity: 0.7;
		cursor: pointer;
		position: absolute;
	}
	.play-button {
		width: 90px;
		height: 60px;
		background-color: #333;
		box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
		z-index: 1;
		opacity: 0.8;
		border-radius: 6px;
		cursor: pointer;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d( -50%, -50%, 0 );
		&:before {
			content: "";
			border-style: solid;
			border-width: 15px 0 15px 26.0px;
			border-color: transparent transparent transparent #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d( -50%, -50%, 0 );
		}
	}
	
}

iframe.youtube {
	padding-top: 0;
}