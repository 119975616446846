.footer {
    max-width: $contentwidth;
    margin: 40px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding:0 20px;
    @include max-screen($screen__l) {
        padding: 0 15px;
    }


    .newsletter {
        display: inline-block;
    }

  

    &__links {
     //   width: 25%;
        display: inline-block;
        h3 {
            font-weight: 600;
            margin-bottom: 40px;
        }
        ul {
            list-style-type: none;
            list-style-position: inside;
            padding-left: 0;
        }
        a,
        a:hover,
        a:visited {
            color: #191919;
        }
        &__item--phone {
            font-weight: 600;
            a {
                background: url('../images/telephone.svg') no-repeat;
                height: fit-content;
                background-size: 30px 30px;
                display: block;
                padding-left: 3em;
                background-position: left center;
            }
        }

        &__item--email {
            font-weight: 600;
            a {
                background: url('../images/email.svg') no-repeat;
                height: fit-content;
                background-size: 30px 30px;
                display: block;
                padding-left: 3em;
                background-position: left center;
            }
        }

        .social-share {
            margin: 16px 0;
            li {
                display: inline-block;
            }
        }

        .payment-methods {
            li {
                display: inline-block;
                margin-right: 8px;
                img {
                    vertical-align: middle;
                }

            }
        }
    }
}

.copyright {
    margin: 0 auto;
    text-align: center;
    display: block;
    max-width: $contentwidth;
    border-top: 1px solid #000000;
    padding: 80px 0 40px 0;
    @include max-screen($screen__l) {
        padding: 80px 15px 40px 15px;
    }
}

.page-footer {
    .logo {
        margin: 0 auto 16px auto;
        display: block;
        filter: gray;
        filter: grayscale(100%);
        transform: scale(0.8, 0.8);
        -ms-transform: scale(0.8, 0.8);
        -webkit-transform: scale(0.8, 0.8);
    }
}


