@include min-screen($screen__m) {
    .opc-wrapper {
        width: 66% !important;

    }
    .opc-sidebar {
        width: 30% !important;
    }
}

.cms-index-index,
.category-free-bespoke-garden-design-service,
.category-kostenloser-design-service,
.category-service-design-gratuit,
.category-gratis-ontwerpdienst {
    .column.main {
        max-width: 960px;
        margin: 244px auto;
        background-color: #ffffff;
        @include max-screen($screen__m) {
            margin: 60px 0;
        }
    }
}

.category-free-bespoke-garden-design-service,
.category-kostenloser-design-service,
.category-service-design-gratuit,
.category-gratis-ontwerpdienst {
    .columns {
        background-color: #ffffff;
    }
}

#checkout > div {
    margin-left: 15px;
    margin-right: 15px;
}