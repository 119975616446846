.woodblocx_instruction-order-find,
.woodblocx_instruction-order-result {
    .page-title-wrapper {      
        max-width: 100%;  
        background-color: #eeeeee;
        padding-top: 20px;
        margin: 0 auto 20px auto;
        h1 {
            margin-left: 100px;
        }
        @media only screen and (max-width: 1023px)
        {
            padding: 0 15px;
            margin: 0;
        }
    }
}

.instructions-find,
.instructions-result {
    .form.create.instruction {
        @media only screen and (min-width: 768px) {
            min-width: 600px;
            width: 50%;
            margin-top: 9px;
        } 
        @media only screen and (max-width: 767px) {
            margin-top: 7px;
        }      
    }
    
    .fieldset > .legend {
        @media only screen and (max-width: 767px) {
            margin: 0 !important;
        }      
    }
    

    .button {
        @include primary-button;
    }

    .instructions__item__design-name {
        font-style: italic;
    }
}

