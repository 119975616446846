@mixin primary-button {
    background-color: $c-green;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 20px;
    margin: 12px 0;
    color: #ffffff;

    &:visited {
        color: #ffffff !important;
        text-decoration: none;
    }
}