// checkout page form address spacing
.field.street.admin__control-fields {
    .control {
        .field {
            margin-bottom: 20px;
            label {
                display: none;
            }
        }
    }
}

//change all the font awsoem icons to svg
.cart-container .form-cart .action.continue:before,
.checkout-payment-method .payment-option-title .action-toggle:after {
    content: " ";
    background: url("../images/chevron.svg") no-repeat;
    width: 10px;
    height: 20px;
}

.cart-container .form-cart .action.continue:before {
    transform: rotate(180deg);
    height: 13px;
    margin-right: 10px;
    position: relative;
    left: 6px;
}

.cart-container .form-cart .action.update:before {
    content: " ";
    background: url("../images/update.svg") no-repeat;
    width: 15px;
    height: 12px;
    margin: 2px 6px 0;
}

.opc-block-summary .items-in-cart > .title:after,
.minicart-items .product .toggle:after {
    transform: rotate(90deg);
    margin-right: 15px;
    margin-top: -1px;
    margin-left: 3px;
}
.minicart-items .product.active > .toggle:after {
    transform: rotate(270deg);
    margin-left: 12px;
}
.fieldset > .field .note:before {
    content: " ";
    background: url("../images/caret.svg") no-repeat;
    width: 15px;
    height: 12px;
    margin: -2px 0 0;
}
.field-tooltip .field-tooltip-action:before {
    content: " ";
    background: url("../images/help.svg") no-repeat;
    width: 17px;
    height: 17px;
    margin-top: 4px;
    opacity: 0.3;
}
.field-tooltip._active .field-tooltip-action:before,
.field-tooltip .field-tooltip-action:hover:before {
    opacity: 1;
}
.checkout-payment-method .payment-option-title .action-toggle:after {
    transform: rotate(90deg);
    opacity: 0.3;
}
.checkout-payment-method .payment-option._active .payment-option-title .action-toggle:after {
    transform: rotate(270deg);
    margin-left: 12px;
}
.opc-block-shipping-information .shipping-information-title .action-edit:before {
    content: " ";
    background: url("../images/settings.svg") no-repeat;
    width: 15px;
    height: 20px;
    opacity: 0.3;
}
.opc-block-shipping-information .shipping-information-title .action-edit:hover:before {
    opacity: 1;
}

@media (max-width: 1400px) {
    .opc-progress-bar {
        padding: 0 20px;
    }
}

@include max-screen($screen__m) {
    .opc-progress-bar {
        padding: 0 15px;
    }
    .checkout-payment-method {
        .step-title {
            padding-left: 15px;
            padding-top: 10px;
        }
    }
}

@include min-screen($screen__m) {
    .form.methods-shipping .actions-toolbar {
        padding-top: 15px;
    }
}

#co-payment-form {
    // Hiding the recaptcha checkbox - suggestion from QA
    #recaptcha-checkout-place-order-wrapper {
        input {
            display: none;
        }
    }
    hr {
        display: none;
    }
}

/* collapsible removed so show content */

.cart-summary .block > .content {
    display: block;
}

/* remove collapsible title icons */

.opc-block-summary .items-in-cart > .title:after,
.cart-summary .block > .title:after {
    content: "";
}

.checkout-cart-index {
    .page-title-wrapper {
        max-width: 100%;
        background-color: #eeeeee;
        padding-top: 20px;
        h1 {
            margin-left: 100px;
        }
        @include max-screen($screen__l) {
            margin-left: 0;
            h1 {
                margin: 0;
            }
            padding: 15px;
        }
    }
}


.checkout-onepage-success {

   

    .page-title-wrapper {

        text-align: left;
    } 

    .checkout-success {
        margin-bottom: 4rem;
        &__summary {
            text-align: left;
        }
        span {
            font-weight: 600;
        }
    }

    #registration {
        display: none;
    }


}
