// Cart item images
.cart.table-wrapper {
    .col.item {

        @include max-screen($screen__l) {
            padding-left: 0;
        }
                @include max-screen($screen__m) {
            padding: 25px 0 10px 125px !important;
        }
        .product-image-wrapper {
            .product-image-photo {
                max-width: 110px;
                object-fit: cover;
                @include min-screen($screen__m) {
                    max-width: 85px;
                }
            }
        }
    }
}

.cart-container .checkout-methods-items .action.primary {
    background-color: $c-green;
    color: #ffffff;
    border: none;
}

.cart-totals {
    min-height: 180px;
}

@include max-screen($screen__l) {
    .cart-summary {
        padding: 0;
    }
}

#custom-label
{
    font-weight: 700;
}