// Create account page 
.customer-account-create,
.customer-account-login {
    .page-title-wrapper{
        padding: 0 100px;
    }
    .column, .page-title-wrapper{
        @include max-screen($screen__l) {
            padding: 0 15px;
            margin: 0;
        }
    }
}

.customer-account-login{
    .login-container{
        .fieldset.login{
            &:after{
                margin: 20px 25.8% 0;
                @include max-screen($screen__m) {
                    text-align: left;
                    margin: 10px 0 0;
                }
            }
        }
    }
}

.account.stripe_payments-customer-cards {
    .columns{
        .column.main{
            padding-right: 20px;
        }
    }
}

// Account address book page 
.customer-address-form {
    .form-address-edit{
        .field.primary{
            label[for="street_1"]{
                span{
                    display: none;
                }
            }
        }
        .actions-toolbar{
            .action.primary{
                padding: 7px 15px;
                font-size: 1.4rem;
            }
        }
    }
}
// Account Information page 
.customer-account-edit,.customer-account-create {
    .form-edit-account,.form-create-account{
        .field-tooltip { 
            top: 0;
            margin-left: 0;
            #tooltip-label{
                span{
                    display: none;
                }
            }
            .field-tooltip-action {
                &:before{
                    margin-top: 0;
                }
            }
            .field-tooltip-content{
                left: 29px;
                @include max-screen($screen__xl) {
                    width: 180px;
                    top: -45px;
                    &::before, &::after{
                        top: 45px;
                    }
                }
                @include max-screen($screen__l) {
                    top: -115px;
                    left: -92px;
                    width: 202px;
                    &::before, &::after{
                        top: unset;
                        left: 90px;
                        bottom: -20px;
                        transform: rotate(-90deg);
                    }
                }
                @include max-screen($screen__m) {
                    top: -95px;
                    font-size: 12px;
                    padding: 10px;
                    &::before, &::after{
                        left: 92px;
                    }
                }
                @include max-screen($screen__xs) {
                    top: -97px;
                    left: -156px;
                    width: 175px;
                    &::before, &::after{
                        left: 155px;
                    }
                }
            }
        }
    }
}

// Account page - common styling 
.account{
    .page-main{
        .columns{
            padding: 0 20px;
            @include max-screen($screen__l) {
                flex-direction: column;
                padding: 0 15px;
            }
            // Account page side bar    
            .sidebar.sidebar-main{
                margin-right: 15px;
                @include max-screen($screen__m) {
                    margin-right: 0;
                }
                .account-nav{
                    @include max-screen($screen__m) {
                        margin-bottom:0;
                    }
                    .nav{
                        list-style: none;
                        padding-left: 0;
                        @include max-screen($screen__m) {
                            margin-bottom:10px;
                        }
                        .item {
                            .delimiter{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .page-title-wrapper{
        padding: 0 20px;
        @include max-screen($screen__l) {
            margin-left: auto;
            padding: 0 15px;
        }
    }
    
    .actions-toolbar{
        .secondary{
            float: left;
            clear: left;
            margin-top: 15px;
            @include max-screen($screen__m) {
                margin-top: 10px;
            }
        }
    }
}