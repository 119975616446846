.order-status {
    width: 100%;
}

.order-status-arrival {
    background-color: #eeeeee;
    font-size: 20px;
    width: initial;
    display: block;
    &__date {
        font-size: 40px;
    }
    padding: 20px;
}
.order-status-visualisation {
    margin: 20px 0;
    ol {
        list-style-type: none;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        @include max-screen($screen__l) {
            flex-direction: column;
        }
        margin: 0;
        padding: 0;
        li {
            background-color: #eeeeee;
            padding: 10px 20px;
            position: relative;
            &.order-step-completed {
                background-color: $c-green;
                color: #ffffff;
                &:after {
                    border-left-color: $c-green;
                }
            }

            &:not(:last-child) {
                margin-right: 10px;
            }

            &:after {
                position: absolute;
                top: 0;
                right: 0;
                content: "";
                display: inline-block;
                border: 20px solid transparent;
                border-left: 10px solid #eeeeee;
                border-right: 0;
                margin-right: -10px;
                transition: all 0.2s;
            }
            &:not(:first-child):before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                display: inline-block;
                border: 20px solid transparent;
                border-left: 10px solid #ffffff;
                border-right: 0;
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}
.details-container {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "order-details shipping-details"
        "order-details building-instructions";

    @include max-screen($screen__l) {
        display: flex;
        flex-direction: column;
        .shipping-details {
            order: 1;
        }
        .order-details {
            order: 2;
        }
        .building-instructions {
            order: 3;
        }
    }
    > div {
        border: 2px solid #eeeeee;
    }
    h3 {
        margin-top: 0;
        font-size: 30px;
        margin-bottom: 1rem;
        background-color: #eeeeee;
        padding: 10px 20px;
    }
    ol {
        margin-left: 10px;
        list-style-type: none;
    }
}

.order-details,
.shipping-details {
    > dl,
    > ol,
    > ul {
        padding: 20px;
        margin-bottom: 0;
        margin-left: 0;
    }
    ol,
    ul {
        list-style-type: none;
    }
}

.order-details {
    grid-area: order-details;
    &__item {
        display: flex;
        @include max-screen($screen__l) {
            flex-direction: column;
        
        }
        img {
            width: 160px;
            height: 160px;
            object-fit: cover;
        }
        h4 {
            font-size: 2rem;
            margin-top: 0;
        }
        &__details {
            margin-left: 10px;
            @include max-screen($screen__l) {
                margin: 20px 0;
                border-bottom: 1px solid #eeeeee;
            }
            &__options {
                dt {
                }
                dd {
                }
            }
        }
    }
}

.shipping-details {
    grid-area: shipping-details;
    a {
        text-overflow: ellipsis;
        white-space: wrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        hyphens: auto;
    }
    ul {
        padding-left: 0;
    }
}

.building-instructions {
    grid-area: building-instructions;
}
