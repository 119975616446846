.review__intro {
    background-color: #eeeeee;
    line-height: 40px;
    margin-bottom: 20px;
    left: -15px;
    padding: 0 15px;
    width: 100%;
    position: relative;

    @include max-screen($screen__m) {
        box-sizing: border-box;
        width: calc(100% + 20px);
        left: -10px;
        padding: 0 10px;
        top: -10px;
    }

    img {
        float: right;
        padding: 10px 0;
        @include max-screen($screen__m) {
            display: none;
        }
    }

    a {
        text-decoration: underline;
    }

    p {
        font-size: 16px;
    }
}

.review__summary--product,
.review__summary--service {
    display: inline-block;
    margin: 0 0 20px 0;
    .rating {
        font-size: 42px;
        font-weight: 600;
    }
    &__count {
        font-size: 17px;
        font-weight: 600;
    }
    .review__rating {
        width: 77px;
        &__stars {
            height: 15px;
            background: url("../images/stars.svg") no-repeat;
        }
    }
}
.review__summary--product {
    .review__rating {
        display: inline-block;
    }
    .review__summary__count {
        display: inline-block;
        position: relative;
        top: -2px;
        a {
            text-decoration: none;
        }
    }
}
ul.review__items {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    .review__rating {
        width: 77px;
        margin-bottom: 1.5rem;
        &__stars {
            height: 15px;
            background: url("../images/stars.svg") no-repeat;
        }
    }
    h4 {
        font-size: 30px;
        margin-top: 0;
        &:before {
            content: "\201C";
        }
        &:after {
            content: "\201D";
        }
    }

    > li {
        display: inline-block;
        width: 48%;
        margin-bottom: 60px;
        @include max-screen($screen__m) {
            width: 100%;
        }
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

.review__quote {
    &--vendor {
        line-height: 2rem;
        border-left: 1px solid #191919;
        padding-left: 24px;
        margin: 24px 0;
    }
    &--customer {
        line-height: 2rem;
    }
}

.review__quote__author {
    &--customer,
    &--vendor {
        font-weight: 600;
        p {
            &:before {
                content: "\201C";
            }
            &:after {
                content: "\201D";
            }
        }
    }
}

.review__items__more {
    margin: 0 auto 60px auto;
    display: block;
    border: 2px solid #191919;
    text-transform: uppercase;
    padding: 10px 40px;
    background-color: transparent;
}

.service-review-summary {
    background-color: #474344;
    padding: 6px 8px;
    color: #ffffff;

    &__title {
        display: inline-block;
        margin-right: 5px;
        @include max-screen($screen__m) {
            display: block;
            margin: 0;
        }
        span {
            display: block;
            font-size: 10px;
            display: block;
            margin-top: 3px;
            font-weight: 600;
            @include max-screen($screen__m) {
                font-size: 8px;
                text-align: center;
                font-weight: 400;
                margin: 0;
            }
        }
        img {
            display: block;
            @include max-screen($screen__m) {
                height: 12px;
                width: 51px;
                margin: 0 auto;
            }
        }
    }

    &__rating {
        display: inline-block;
        @include max-screen($screen__m) {
            display: block;
            margin-top: 3px;
        }
        div {
            display: block;
        }
        .review__rating {
            width: 77px;
            &__stars {
                height: 15px;
                background: url("../images/stars-yellow.svg") no-repeat;
                @include max-screen($screen__m) {
                    height: 10px;
                    background-size: 55px 10px;
                }
            }
            @include max-screen($screen__m) {
                width: 55px;
            }
        }
        span {
            font-size: 10px;
            text-align: center;
            display: block;
            margin-top: 3px;
            @include max-screen($screen__m) {
                font-size: 8px;
                margin: 0;
            }
        }
    }
}
